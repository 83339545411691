import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Flip } from "gsap/Flip";

// Register plugins
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Flip);

// Expose GSAP and its plugins to the global scope
window.Flip = Flip;
window.ScrollTrigger = ScrollTrigger;
window.gsap = gsap;
