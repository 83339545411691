import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);

// Extract breakpoints as integers
const screensInt = {};
Object.keys(fullConfig.theme.screens).forEach((screen) => {
    if (typeof fullConfig.theme.screens[screen] !== "string") {
        return;
    }

    screensInt[screen] = parseInt(
        fullConfig.theme.screens[screen].replace("px", "")
    );
});

window.tailwind = {
    screensInt,
};
