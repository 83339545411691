import Lenis from "@studio-freight/lenis";

if (document.body.dataset.editmode !== "true") {
    // Initialize when not in editmode
    let lenis = null;
    function initiateLenis() {
        lenis = new Lenis({
            duration: 0.7,
            easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        });

        lenis.on("scroll", ScrollTrigger.update);

        gsap.ticker.add((time) => {
            lenis.raf(time * 1000);
        });
    }

    function handleCustomEvent(event) {
        if (event.detail.action === "disable") {
            lenis.destroy();
        } else if (event.detail.action === "enable") {
            initiateLenis();
        }
    }
    initiateLenis();
    document.addEventListener("lenis-control", handleCustomEvent);
}
