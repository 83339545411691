import Cookies from 'js-cookie'

let currentCookies = null

const getCookies = () => {
  // if cookies set already, return. if cookies set and empty, return false.
  if (currentCookies && Object.entries(currentCookies).length > 0) return currentCookies
  if (currentCookies && Object.entries(currentCookies).length === 0) return false

  currentCookies = JSON.parse(Cookies.get(getCookieKey()) || '{}')

  return Object.entries(currentCookies).length > 0 ? currentCookies : false
}

const setCookies = (cookies) => {
  currentCookies = cookies

  Cookies.set(getCookieKey(), JSON.stringify(cookies), {
    path: '/',
    expires: 30, // in days
    sameSite: true,
  })
}

const getCookieKey = () => {
  const gdpr = document.querySelector('#GdprConsent');
  const id = gdpr.dataset.gdprId;
  const version = gdpr.dataset.gdprVersion;
  return 'accc-' + id + '-' + version;
}

export default {}
export {
  getCookies,
  setCookies,
}
